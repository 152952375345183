import styled from 'styled-components';

export const ComponentWrapper = styled.div `
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  color: var(--text);
  display: block;
  font-size: 1.1rem;
  line-height: 1.5rem;
  overflow: hidden;
  position: fixed;
  @media only screen and (max-width: 1100px) {
    transform: translateX(-190px);
  }
  .tooltip-wrapper {
    display: flex;
  }
  .image-wrapper {
    height: 5rem;
    padding: 1rem;
    width: 5rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .description-wrapper {
    padding: 1rem 1rem 1rem 0;
    width: 15rem;
    .description-container {
      display: flex;
      .percent {
        margin-right: 0.5rem;
        min-width: 3rem;
      }
    }
    .short-description {
      font-style: italic;
      margin: 1rem 0; 
    }
    .name {
      font-size: 1.3rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }
}`