import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './StatsTable.css';

// The headings data array that this takes is formatted as 
// headings={[
//   {rank: 'Rk'},
//   {playerName: 'Player'},
//   {role: 'Role'},
//   {team_name: 'Team'},
//   {kills: 'Kills'},
//   {deaths: 'Deaths'},
//   ...
// ]}

const StatsTable = (props) => {
  const { data, headings, showIndex, sortFunction, sortCriteria } = props;
	return (
    <ComponentWrapper className="c-StatsTable">
      <table>
        <thead>
          <tr>
            {Object.keys(headings).map((item, i)=>
            <th key={headings[item] + ' ' + i} onClick={sortFunction ? (() => sortFunction(Object.keys(headings)[i])) : void(0) } className={`${Object.keys(headings)[i]} ${Object.keys(headings)[i] === sortCriteria ? 'active' : ''}`}>{headings[item]}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {data && data.map((item, index)=>
            <tr key={index + item}>
              {Object.values(item).map((sub_item, sub_index)=>
                <React.Fragment key={sub_index + ' ' + sub_item}>
                { sub_index === 0 && showIndex &&
                  <>
                    <td>
                      {index + 1}
                    </td>
                    <td className={Object.keys(item)[sub_index]}>
                      {sub_item}
                    </td>
                  </>
                }
                { sub_index === 0 && showIndex === false &&
                  <td className={Object.keys(item)[sub_index]}>
                    {sub_item}
                  </td>
                }
                { sub_index !== 0 &&
                  <td className={Object.keys(item)[sub_index]}>
                    {Number(sub_item)?Number(sub_item).toLocaleString():sub_item}
                  </td>
                }
                </React.Fragment>
              )}
            </tr>
          )}
          {data && data.length === 0 &&
            <tr className="no-stats"><td><h2>No Stats Available</h2></td></tr>
          }
        </tbody>
      </table>
    </ComponentWrapper>
	); 
};

StatsTable.propTypes = {
  data: PropTypes.array,
  headings: PropTypes.object.isRequired,
  showIndex: PropTypes.bool,
  sortFunction: PropTypes.func
}

export default StatsTable;