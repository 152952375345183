import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './PlayersOfTheWeekCard.css';

const PlayersOfTheWeekCard = (props) => {
  const {
    data
  } = props;
	return (
    <ComponentWrapper className="c-PlayersOfTheWeekCard">
      <h2>Players of the Week</h2>
      <div className="players-container">
        {data && data.length > 0 && data.map((player)=>
          <div key={player.name} className="card">
            <strong>{player.name}</strong>
            <Link to={`/players/${player.name.toLowerCase()}`} className="image">
              <img src={`https://webcdn.hirezstudios.com/smite-esports/players/${player.name.toLowerCase()}.png`} alt={player.name} />
            </Link>
            <Link to={`/teams/${player.short_name.toLowerCase()}`} className="team-icon">
              <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${player.short_name}.png`} alt={player.team} />
            </Link>
            <Link to={`/players/${player.name.toLowerCase()}`}>
              <div className="role">{player.role}</div>
              <div className="team">{player.team}</div>
            </Link>
          </div>
        )}
        {data && data.length <= 0 &&
          <p className="no-message">No Players of the Week</p>
        }
      </div>
    </ComponentWrapper>
	); 
};

PlayersOfTheWeekCard.propTypes = {
  data: PropTypes.array
}

export default withContext(PlayersOfTheWeekCard);