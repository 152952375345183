import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import withContext from 'store/withContext';

import { ComponentWrapper } from './TeamLeaderboardCard.css';

const TeamLeaderboardCard = (props) => {
  const {
    data
  } = props;
	return (
    <ComponentWrapper className="c-TeamLeaderboardCard">
      <h2>Team Leaderboard</h2>
      <div className="teams-container">
        {data && data.length > 0 && data.map((team)=>
          <div key={team.title} className="card">
            <div className="left">
              <div className="title">{team.title}</div>
              <Link to={`/teams/${team.short_name.toLowerCase()}`} className="team-icon">
                <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team.short_name}.png`} alt={team.name} />
              </Link>
              <strong>{team.team}</strong>
            </div>
            <div className="right">
              {team.stats && team.stats.map((stat)=>
                <div key={stat.title} className="stat">{stat.title}<strong>{Number(stat.value)?Number(stat.value).toLocaleString():stat.value}</strong></div>
              )}
            </div>
          </div>
        )}
        {data && data.length <= 0 &&
          <p className="no-message">No Teams</p>
        }
      </div>
    </ComponentWrapper>
	); 
};

TeamLeaderboardCard.propTypes = {
  data: PropTypes.array
}

export default withContext(TeamLeaderboardCard);