import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TrendingCard.css';

const TrendingCard = (props) => {
  const {
    data
  } = props;
	return (
    <ComponentWrapper className="c-TrendingCard">
      <h2>Trending</h2>
      <div className="trending-container">
        {data && data.length > 0 && data.map((item)=>
          <div key={item.title} className="card">
            <div className="left">
              <div className="title">{item.title}</div>
              <Link to={`/players/${item.name.toLowerCase()}`} className="image">
                <img src={`https://webcdn.hirezstudios.com/smite-esports/players/${item.name.toLowerCase()}.png`} alt={item.name} />
              </Link>
              <Link to={`/teams/${item.short_name.toLowerCase()}`} className="team-icon">
                <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${item.short_name}.png`} alt={item.team} />
              </Link>
              <strong>{item.name}</strong>
              <div className="team-role">{`${item.team} | ${item.role}`}</div>
            </div>
            <div className="right">
              {item.stats && item.stats.map((stat)=>
                <div key={stat.title} className="stat">{stat.title}<strong>{Number(stat.value)?Number(stat.value).toLocaleString():stat.value}</strong></div>
              )}
            </div>
          </div>          
        )}
        {data && data.length <= 0 &&
          <p className="no-message">No Trending Players</p>
        }
      </div>
    </ComponentWrapper>
	); 
};

TrendingCard.propTypes = {
  data: PropTypes.array
}

export default withContext(TrendingCard);