import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  border: 1px solid var(--border);
  box-sizing: border-box;
  overflow-x: scroll;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    overflow-x: scroll;
  }
  table {
    width: 100%;
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    thead {
      background: var(--primary);
      th {
        box-sizing: border-box;
        color: var(--background);
        cursor: pointer;
        font-size: 1.4rem;
        font-weight: 600;
        padding: 1rem;
        text-align: left;
        text-transform: uppercase;
        &:not(:first-of-type) {
          text-align: right;
        }
        &.active {
          text-decoration: underline;
        }
        &.team_id {
          display: none;
        }
        &.team_name, &.role, &.team {
          text-align:left
        }
      }
    }
    tbody {
      tr {
        box-sizing: border-box;
        border-bottom: .1rem solid var(--border);
        &:hover {
          background: var(--secondary-background);
        }
      }
      .no-stats span {
        font-size: 2rem;
        font-weight: bold;
        padding: 2rem 0 2rem 2rem;
      }
      td {
        box-sizing: border-box;
        font-size: 1.4rem;
        padding: 1rem;
        width:0px;
        &:not(:first-of-type) {
          font-size: 1.4rem;
          text-align: right;
          
        }
        &.team_id, &.short_name {
          display: none;
        }
        &.team_name, &.role, &.team {
          text-align: left;
        }
      }
    }
  }
`;