import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './Tooltip.css';

//Components

const ToolTip = (props) => {
  const { toolTipData, Xposition, Yposition } = props;
  return (
    <ComponentWrapper className="c-ToolTip" style={{top: (Yposition + 30) + 'px', left: (Xposition - 10) + 'px'}}>
      {toolTipData.DeviceName &&
        <div className="tooltip-wrapper">
            <div className="image-wrapper">
              <img src={
                `https://webcdn.hirezstudios.com/smite/item-icons/${toolTipData.DeviceName.toLowerCase().replace(/[^a-zA-Z- ]/g, "").replace(/\s+/g, "-")}.jpg`
              }
              alt = {
                toolTipData.DeviceName
              }
              />
            </div>
            <div className="description-wrapper">
              <div className="name">{toolTipData.DeviceName}</div>
              {toolTipData.ItemDescription.Menuitems.map((desc, index) =>
                <div className="description-container" key={desc.Description + index}>
                  <div className="percent">{desc.Value}</div>
                  <div className="description">{desc.Description}</div>
                </div>
              )}
              <div className="short-description">
                {toolTipData.ShortDesc !== '' && 
                  toolTipData.ShortDesc
                }
                {toolTipData.ShortDesc === '' && 
                  toolTipData.ItemDescription.SecondaryDescription
                }
              </div>
              <div className="about-text"/>
            </div>
          </div>
      }

    </ComponentWrapper>
	); 
};

ToolTip.propTypes = {
  toolTipData: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  Xposition: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired
  ]),
  Yposition: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired
  ]),
}

export default ToolTip;