import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    border-bottom: .3rem solid transparent;
    color: var(--text);
    cursor: pointer;
    font-family: var(--font-body);
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: .65rem;
    text-transform: uppercase;
    transition: border .2s ease-in-out;
    &:hover,
    &.is-active {
      border-color: var(--primary);
    }
    &:not(:last-of-type) {
      margin-right: 3.2rem;
    }
  }
`;