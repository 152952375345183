import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TextNavigationList.css';

class TextNavigationList extends Component {
  constructor() {
    super();
    this.state = {
      active: undefined
    };
  }

  updateStateCallback = (active) => {
    this.setState({
      active: active
    });
    if (this.props.activeCallback) {
      this.props.activeCallback(active);
    }
  }

  componentDidMount() {
    if (this.state.active === undefined) {
      this.setState({
        'active': this.props.data[0]
      });
    }
  }

  render() {
    const { data } = this.props;
    return (
      <ComponentWrapper className="c-TextNavigationList">
        {data.map((item)=>
          <div key={item} className={this.state.active === item ? 'is-active' : ''} onClick={()=>this.updateStateCallback(item)}>
            {item}
          </div>
        )}
      </ComponentWrapper>
    );
  };
};

TextNavigationList.propTypes = {
  data: PropTypes.array.isRequired,
  activeCallback: PropTypes.func
}

export default TextNavigationList;