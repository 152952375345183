import styled from 'styled-components';

export const ComponentWrapper = styled.div `
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 120rem;
  overflow: hidden;
  padding: 1.6rem 3.2rem 3.4rem 3.2rem;
  width: 100%;
  .no-message {
    color: var(--secondary-text);
  }
  h2 {
    color: var(--text);
    display: inline-block;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-top: .3rem solid var(--primary);
      border-right: .3rem solid var(--primary);
      width: .6rem;
      height: .6rem;
      top: .4rem;
      transform: rotate(45deg);
      right: -1.75rem;
    }
  }
  .players-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .card {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 5rem;
      position: relative;
      min-width: 22.5rem;
      @media only screen and (max-width: 1299px) {
        padding: 2.5rem 5rem;
      }
      strong {
        color: var(--text);
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      .image {
        background: var(--tertiary-background);
        border-radius: 10rem;
        height: 12.5rem;
        margin: 1rem 0 1.4rem 0;
        overflow: hidden;
        position: relative;
        width: 12.5rem;
        img {
          height: auto;
          width: 100%;
          &:before {
            content: url('https://webcdn.hirezstudios.com/smite-esports/players/unknown.png');
          }
        }
      }
      a {
        text-decoration: none;
      }
      .team-icon {
        background: var(--team-background);
        bottom: 4.7rem;
        border-radius: 10rem;
        box-sizing: border-box;
        overflow: hidden;
        right: 2.5rem;
        padding: .75rem;
        position: absolute;
        height: 5rem;
        width: 5rem;
        @media only screen and (max-width: 1299px) {
          bottom: 7.2rem;
        }
        img {
          height: auto;
          width: 100%;
        }
      }
      .role {
        color: var(--text);
        font-size: 1.4rem;
        padding-bottom: .7rem;
        text-align: center;
        text-transform: uppercase;
      }
      .team {
        color: var(--text);
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }
`