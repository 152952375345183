import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 5.3rem 0;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  h1 {
    font-size: 2.4rem;
  }
  .stats-container {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    margin: 0 auto;
    max-width: 146.6rem;
    min-height: 75rem;
    padding: 5rem;
    .c-TextNavigationList {
      margin: 2.5rem 0 3.5rem 0;
    }
    .c-TrendingCard {
      margin: 2rem auto 2rem auto;
    }
    .c-StatsTable {
      @media only screen and (max-width: 1299px) {
        width: 100%;
      }
      tr {
        th:nth-of-type(1),
        td:nth-of-type(1) {
          text-align: left;
        }
        td.role {
          text-align: left;
          padding: 1rem;
        }
        td.team {
          text-align: left;
        }
      }
    }
  }
  .filter-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    @media screen and (max-width: 763px) {
      flex-direction: column;
    }
    select {
      background: #f1f1f1 url("https://webcdn.hirezstudios.com/smite-esports/assets/select-bkg-gray.png") right no-repeat;
      border: .1rem solid var(--border);
      border-radius: 0;
      box-sizing: border-box;
      color: #000;
      display: block;
      font-family: var(--font-body);
      font-size: 1.4rem;
      margin-bottom: 1rem;
      max-width: 26em;
      padding: 1rem;
      position: relative;
      width: 100%;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      @media screen and (max-width: 763px) {
        max-width: 100%;
        margin-left: initial;
        margin-top: 2rem;
      }
      &:focus {
        border-color: var(--text);
        outline: none;
      }
      ::-webkit-input-placeholder {
        color: var(--text);
        opacity: 0.5;
      }
      :-ms-input-placeholder {
        color: var(--text);
        opacity: 0.5;
      }
      ::placeholder {
        color: var(--text);
        opacity: 0.5;
      }
      option {
        color: #000;
      }
    }
  }
  .teams-wrapper {
    /* .c-StatsTable {
      tr {
        th:nth-of-type(2),
        td:nth-of-type(3) {
          text-align: left;
        }
        td:nth-of-type(2),
        td:nth-of-type(4) {
          display: none;
        }
      }
    } */
  }
`;

export default PageWrapper;