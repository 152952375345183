import styled from 'styled-components';

export const ComponentWrapper = styled.div `
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 120rem;
  overflow: hidden;
  padding: 1.6rem 3.2rem 3.4rem 3.2rem;
  width: 100%;
  .no-message {
    color: var(--secondary-text);
  }
  h2 {
    color: var(--text);
    display: inline-block;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-top: .3rem solid var(--primary);
      border-right: .3rem solid var(--primary);
      width: .6rem;
      height: .6rem;
      top: .4rem;
      transform: rotate(45deg);
      right: -1.75rem;
    }
  }
  .teams-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    .card {
      align-items: start;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 0 3rem;
      position: relative;
      @media only screen and (max-width: 1299px) {
        padding: 3rem 6rem;
      }
      .title {
        color: var(--text);
        font-size: 1.4rem;
        text-align: center;
        text-transform: uppercase;
      }
      strong {
        color: var(--text);
        display: block;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      .team-icon {
        display: block;
        height: 12.5rem;
        margin: 1.5rem 0;
        position: relative;
        width: 12.5rem;
        img {
          height: auto;
          width: 100%;
        }
      }
      .right {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        padding-left: 5.6rem;
        .stat {
          color: var(--text);
          font-size: 1.2rem;
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          &:first-of-type {
            padding-bottom: 1.75rem;
          }
          &:nth-of-type(2) {
            border-bottom: .1rem solid var(--primary);
            border-top: .1rem solid var(--primary);
            box-sizing: border-box;
            padding: 1.75rem 0;
          }
          &:last-of-type {
            border-bottom: none;
            padding-top: 1.75rem;
          }
          strong {
            display: block;
            font-size: 2.4rem;
            font-weight: 800;
            padding-top: .5rem;
            text-align: left;
          }
        }
      }
    }
  }
`