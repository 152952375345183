import styled from 'styled-components';

export const ComponentWrapper = styled.div `
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 120rem;
  overflow: hidden;
  padding: 1.6rem 3.2rem 3.4rem 3.2rem;
  width: 100%;
  .no-message {
    color: var(--secondary-text);
  }
  h2 {
    color: var(--text);
    display: inline-block;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-top: .3rem solid var(--primary);
      border-right: .3rem solid var(--primary);
      width: .6rem;
      height: .6rem;
      top: .4rem;
      transform: rotate(45deg);
      right: -1.75rem;
    }
  }
  .trending-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .card {
      align-items: start;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 0 3rem;
      position: relative;
      &:nth-child(1) {
        .left {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 27rem;
          .team-icon {
            bottom: 9rem;
            right: -2rem;
          }
        }
      }
      @media only screen and (max-width: 1299px) {
        padding: 3rem;
      }
      .left {
        position: relative;
      }
      .title {
        color: var(--text);
        font-size: 1.4rem;
        text-align: center;
        text-transform: uppercase;
      }
      strong {
        color: var(--text);
        display: block;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      .team-role {
        color: var(--text);
        font-size: 1.2rem;
        padding-top: .7rem;
        text-align: center;
      }
      .image {
        background: var(--secondary-background);
        border-radius: 10rem;
        display: block;
        height: 12.5rem;
        margin: 1rem 0 1.4rem 0;
        overflow: hidden;
        position: relative;
        width: 12.5rem;
        img {
          height: auto;
          width: 100%;
          &:before {
            content: url('https://webcdn.hirezstudios.com/smite-esports/players/unknown.png');
          }
        }
      }
      .team-icon {
        align-items: center;
        background: var(--background);
        bottom: 4.7rem;
        border-radius: 10rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        overflow: hidden;
        right: -2.5rem;
        padding: .75rem;
        position: absolute;
        height: 5rem;
        width: 5rem;
        @media only screen and (max-width: 1299px) {
          bottom: 4.7rem;
        }
        img {
          height: auto;
          width: 100%;
        }
      }
      .right {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        padding-left: 5.6rem;
        min-width: 11.4rem;
        .stat {
          color: var(--text);
          font-size: 1.2rem;
          font-weight: bold;
          text-align: left;
          white-space: nowrap;
          text-transform: uppercase;
          max-width: 4.5rem;
          &:first-of-type {
            padding-bottom: 1.75rem;
          }
          &:nth-of-type(2) {
            border-bottom: .1rem solid var(--primary);
            border-top: .1rem solid var(--primary);
            box-sizing: border-box;
            padding: 1.75rem 0;
          }
          &:nth-of-type(3) {
            border-bottom: .1rem solid var(--primary);
            box-sizing: border-box;
            padding: 1.75rem 0;
          }
          &:last-of-type {
            border-bottom: none;
            padding-top: 1.75rem;
          }
          strong {
            display: block;
            font-size: 2.4rem;
            font-weight: 800;
            padding-top: .5rem;
            text-align: left;
          }
        }
      }
  }
`